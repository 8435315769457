.pop-up-outer {
  display: grid;
  align-items: center;
  justify-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.6);
  font-family: Roboto, sans-serif;
}

.pop-up {
  display: grid;
  max-width: 542px;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(68, 21, 85, 0);
  background-color: #ffffff;
}

.main-field {
  margin: 0 25px;
  color: black;
}

.header-field {
  font-size: 26px;
  letter-spacing: 1px;
}

.desc-field {
  font-size: 16px;
  margin-top: 5px;
  font-weight: 300;
  line-height: 22px;
}

.main-button {
  margin: 25px 0;
  display: grid;
}

.accept-button {
  align-self: center;
  justify-self: center;
  width: 140px;
  height: 50px;
  border: none;
  border-radius: 6px;
  background-color: #187f6a;
  color: white;
  font-size: 18px;
  letter-spacing: 1px;
  padding: 0 20px;
}

.accept-button-icon {
  position: relative;
  width: 16px;
  height: 16px;
  top: 1.5px;
  left: 5px;
}

.pop-up-logo {
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 15px;
}

@media (max-width: 550px) {
  .header-field {
    font-size: 20px;
    line-height: 24px;
  }

  .desc-field {
    line-height: 19px;
    font-size: 13px;
  }

  .pop-up {
    margin: 13px;
  }
}
