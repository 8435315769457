.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 8%;
  background: #081316f6;
  color: white;
}

.navbar-name {
  display: flex;
  width: 20%;
  align-items: center;
  cursor: pointer;
  margin-left: 3%;
}

.navbarItemsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  flex-wrap: wrap;
}

.navbarItem {
  display: flex;
  border: none;
  background-color: Transparent;
  color: white;
  cursor: pointer;
  padding-right: 5%;
  text-decoration: none;
  flex-wrap: wrap;
}

.BeckaWeb {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}

@keyframes rotate {
  0% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(0rem);
  }
}
