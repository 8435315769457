.background-img {
  background: url("../images/whitefox.jpeg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #314855;
}
.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Rectangle 1 */

.rectangle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 85vw;
  height: 200vh;
  background-color: rgba(1, 1, 2, 0.95);
  border-radius: 31px;
  margin-top: 15vh;
  margin-bottom: 5vh;
  overflow: hidden;
  color: white;
}

/* Page1 */
.container1-left {
  display: flex;
  flex: 50%;
  height: 75vh;
  justify-content: center;
  padding-top: 15%;
  padding-left: 10vw;
}
.container1-right {
  display: flex;
  flex: 50%;
  height: 75vh;
  justify-content: center;
  padding-top: 15%;
  padding-right: 10vw;
}

.img_rounded-corners {
  top: 20vh;
  height: 20vh;
  border-radius: 15%;
  background-repeat: no-repeat;
}

.typing {
  top: 20vh;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 39px;

  color: rgb(255, 255, 255);
}

/* Page2 (contact) */
.single-container {
  display: flex;
  flex: 100%;

  flex-direction: column;
  justify-content: start;
  margin-top: 15vh;
}
.info-me {
  display: flex;
  flex-direction: column;
  height: 50vh;
}
.lineheight-list {
  line-height: 3.5vh;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  margin-top: 1rem;
}

input,
textarea {
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button[type="submit"] {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #0062cc;
}

.medium_image_centered {
  background-repeat: no-repeat;
  border-radius: 15%;
  height: 20vh;
}