.footer {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #081316f6;
}

.container-1 {
  display: flex;
  justify-content: center;
  padding: 2%;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 5vw;
}
.container-2 {
  justify-content: center;
  padding-bottom: 1.5%;
  flex-wrap: wrap;
  width: 90vw;
  color: white;
}
.container-3 {
  justify-content: center;
  padding-bottom: 1.5%;
  flex-wrap: wrap;
  width: 5vw;
}

.buttons {
  width: 7rem;
}
